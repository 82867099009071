import React from "react";
import SEO from "../components/SEO";
import { RE } from "../components/Reviews";
import "../assets/css/App.css";
import "../assets/css/index.css";
import { Row, Col } from "react-bootstrap";

import { graphql, Link, StaticQuery } from "gatsby";

import Img from "gatsby-image";
import ContactForm from "../components/ContactForm";
import Layout from "../components/Layout";

export default ( {data} ) => (
  <Layout>
    <SEO
      title="Commercial Underfloor Heating"
      description="Underfloor heating is a cost-effective solution for many commercial premises, allowing you to maximise your square footage. Find out how BBIS Heating can help you today"
      keywords="underfloor heating london, underfloor heating potters bar, underfloor heating hertfordshire, underfloor heating central london, underfloor heating repair, underfloor heating installation, underfloor heating fitting"
    />
    <div class="grey-segment">
      <h1 class="heading-color">Commercial Underfloor heating</h1>
      <p>
      Underfloor heating is becoming increasingly popular for its sleek modern design. It may seem luxurious, but it’s actually a cost-effective solution that heads your commercial space efficiently without the need for radiators that take up space.
      </p>
      <p>
      If you’re looking for an efficient way to maximise heating coverage, BBIS Heating can provide underfloor heating systems for any size of commercial property. 
      </p>
      <h2>Benefits of underfloor heating</h2>
      <ul>
        <li>Maximise space by reducing the need for radiators</li>
        <li>Heats a room more evenly and efficiently, potentially reducing heating costs</li>
        <li>Reduce fire hazards from dusty radiators</li>
        <li>Lower maintenance costs - just boilers or heat pumps need servicing</li>
        <li>Compatible with renewable energy such as <Link to="renewable-energy-air-source-heat-pumps/">air source</Link> and <Link to="/renewable-energy-ground-source-heat-pumps-gshp/">ground source heat pumps</Link></li>
      </ul>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.underfloortwo.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      <Col md className="pbs">
          <Img
            fluid={data.underfloorone.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      </div>
      <div className="white-segment">
      <h3 className="heading-color">Planning a major renovation? BBIS Heating can help</h3>

      <p>
      Underfloor heating is a big project that is best installed in major renovations where a subfloor has been taken up or still needs to be installed. At BBIS Heating, we’re experienced in installing underfloor heating in commercial buildings. Call us on <a href="tel:+448002289800">0800 2289800</a> for a free survey or fill in our form below.
      </p>
      <h4>Why choose BBIS Commercial Heating?</h4>
      <ul>
        <li>50 years of experience in domestic and commercial heating </li>
        <li>5-star ratings and reviews</li>
        <li>Friendly local teams in London, Hertfordshire and surrounding areas</li>
      </ul>
      <h4>Recent commercial underfloor heating projects</h4>
      <p>Essendon Country Club</p>
      <p>We installed underfloor heating for Essendon Country Club’s clubhouse extension. Supplied by 700 metres of pipes buried under a concrete screed, customers at the club now enjoy consistent, evenly distributed heating and can always find a seat thanks to the increase in space because there are no radiators.</p>
      <h4 className="heading-color">FAQ</h4>
      <h5>What is the most efficient underfloor heating?</h5>
      <p>Water-based, or ‘wet’ underfloor heating (UFH), is the most efficient type. With this type of UFH, heated water is gently pumped through pipe circuits which are often installed within a layer of concrete screed. </p>
      <p>Typically, wet UFH systems cost 60% less to run than electric UFH systems due to being able to operate at lower temperatures. Another advantage to wet UFH is that is can run on many types of heat sources, including gas, hydrogen or heat pumps, future-proofing your system as we move to using renewable energy. </p>
      <h5>What floor is best with underfloor heating?</h5>
      <p>The best floor to use with underfloor heating is stone or tile because they are good heat conductors, meaning that they will warm the room up more quickly. Options like luxury vinyl tile (LVT) and engineered wood are also worth considering, but offer a slightly lower heat output than stone or tile.</p>
      <h5>Is it cheaper to keep underfloor heating on all day?</h5>
      <p>Yes, it’s cheaper and therefore more efficient to keep underfloor heating on all day. However, you can still increase and decrease the temperature throughout the day based on your needs. For example, if you’re based in an office, you may only want to set the thermostat to 21°C between 8am-6pm, but set it to 14°C outside of these hours.</p>
      <RE></RE>
      <h3 className='heading-color'>Contact BBIS Heating</h3>
      <ContactForm></ContactForm>

    </div>
  </Layout>
);

export const query = graphql`
  query underfloorheating {
    underfloorone: file(relativePath: { eq: "Projects/ecca.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    underfloortwo: file(relativePath: { eq: "Projects/eccb.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 945) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
  }
`;